<template>
  <div>
	<el-dialog
	  title="新建学校"
	  :visible.sync="schoolDialog.visible"
	  :before-close="schoolDialogClose"
	  class="school-dialog"
	  width="62.5vw">
		<el-form>
			<div class="dialog-item-1">
				<el-form-item label="组织：">
					<el-select placeholder="选择组织" value="" class="input-select">
						<el-option label="组织1" value="选项1"></el-option>
						<el-option label="组织2" value="选项2"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="学校名称：">
					<el-input placeholder="输入内容"></el-input>
				</el-form-item>

				<el-form-item label="学校编号：">
					<el-input placeholder="输入内容"></el-input>
				</el-form-item>

				<el-form-item label="学校简称：">
					<el-input placeholder="输入内容"></el-input>
				</el-form-item>

				<el-form-item label="学校类别：">
					<el-select placeholder="选择类别" value="" class="input-select">
						<el-option label="幼儿园" value="选项1"></el-option>
						<el-option label="小学" value="选项2"></el-option>
						<el-option label="中学" value="选项2"></el-option>
						<el-option label="职业学校" value="选项2"></el-option>
						<el-option label="社会学校" value="选项2"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="学校状态：">
					<el-select placeholder="启用/停用" value="" class="input-select">
						<el-option label="启用" value="选项1"></el-option>
						<el-option label="停用" value="选项2"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="建校日期：">
					<el-date-picker placeholder="选择日期"  type="date"></el-date-picker>
				</el-form-item>

				<el-form-item label="学校面积：">
					<el-input placeholder="输入内容" class="unit"></el-input>
				</el-form-item>

				<el-form-item label="学校网址：">
					<el-input placeholder=""></el-input>
				</el-form-item>

				<el-form-item label="所在省：">
					<el-select placeholder="选择组织" value="" class="input-select">
						<el-option label="组织1" value="选项1"></el-option>
						<el-option label="组织2" value="选项2"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="所在市：">
					<el-select placeholder="选择组织" value="" class="input-select">
						<el-option label="组织1" value="选项1"></el-option>
						<el-option label="组织2" value="选项2"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="所在区/县：">
					<el-select placeholder="选择组织" value="" class="input-select">
						<el-option label="组织1" value="选项1"></el-option>
						<el-option label="组织2" value="选项2"></el-option>
					</el-select>
				</el-form-item>
			</div>

			<div class="dialog-item-2">
				<el-form-item label="详情地址：">
					<el-input placeholder=""></el-input>
				</el-form-item>
			</div>

			<div class="dialog-item-3">
				<div>
					<span>微信公众号：</span>
					<div class="upload-img"></div>
				</div>

				<div>
					<span>图标：</span>
					<div class="upload-img"></div>
				</div>

				<div>
					<span>轮播图：（建议750*300）</span>
					<div class="upload-img"></div>
				</div>
			</div>

			<div class="dialog-item-2">
				<el-form-item label="校园风采：">
					<el-input type="textarea" maxlength="1000" placeholder="输入内容" class="textarea-inputted" show-word-limit></el-input>
				</el-form-item>
			</div>

		</el-form>

		<div slot="footer" align="center">
			<el-button @click="dialogVisible = false" type="primary">提交</el-button>
			<el-button @click="dialogVisible = false">重置</el-button>
		</div>
	  </el-dialog>
  </div>
</template>

<script>
export default {
	props: {
		schoolHandleData: Object,
	},
	data() {
		return {
			schoolDialog: {
				visible: false,
				rules: {

				}
			}
		}
	},
	methods: {
		schoolDialogClose() {
			this.schoolDialog.visible = false;
		}
	},
	watch: {
		schoolHandleData() {
			this.schoolDialog.visible = true;
		}
	}
}
</script>

<style lang="scss" scoped>
.school-dialog {
	/deep/.el-dialog {
		.el-dialog__body {
			.el-form-item__label {
				display: contents;
			}
			.dialog-item-1 {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				.el-input,
				.el-select {
					width: 300px;
				}
				.el-form-item {
					width: 300px;
				}
			}
			.dialog-item-3 {
				display: flex;
				& > div {
					margin-right: 200px;
					& > span {
						margin-bottom: 10px;
						display: block;
					}
				}
			}
			.input-select {
				width: 100%;
			}
			.unit {
				&:after {
					position: absolute;
					top: 50%;
					right: 10px;
					display:block;
					content: '㎡';
					transform: translateY(-50%);
				}
			}
		}
	}
}

.upload-img {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 120px;
	height: 120px;
	color: #cccccc;
	background: #f9f9f9;
	border: 1px solid #e9e9e9;
	cursor: pointer;
}
.upload-img:before {
	padding: 10px;
	content: '+';
	font-size: 22px;
}
.upload-img:after {
	padding: 10px;
	content: '上传图片';
}

</style>
