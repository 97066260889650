<template>
	<div class="course-wrapper">
		<div class="course-wrapper-title">
			<span>学校管理</span>
		</div>
		<div class="course-wrapper-body">
			<!-- 功能按钮 -->
			<div class="course-wrapper-function">
				<div class="function-left">
					<el-input class="input-search" placeholder="请输入学校名称/编号" clearabel></el-input>

					<el-input class="input-search" placeholder="请输入所属组织" clearabel></el-input>

					<el-button type="primary" icon="el-icon-search">搜索</el-button>

					<el-button icon="el-icon-refresh-left">重置</el-button>
				</div>
				<div class="function-right">
					<el-button
            @click="handleSchoolButton('update')"
            icon="el-icon-refresh">同步</el-button>

					<el-button
            @click="handleSchoolButton('insert')"
            type="primary" icon="el-icon-plus">新建学校</el-button>
				</div>
			</div>

			<el-table :data="schoolTableData" border>
				<el-table-column prop="" label="ID" type="index" width="80" align="center"></el-table-column>

				<el-table-column prop="" label="学校名称"></el-table-column>

				<el-table-column prop="" label="学校编号"></el-table-column>

				<el-table-column prop="" label="所属组织"></el-table-column>

				<el-table-column prop="" label="学校地址"></el-table-column>

				<el-table-column prop="" label="类别" width="80" align="center"></el-table-column>

				<el-table-column prop="" label="状态" width="80" align="center">
          <el-switch active-color="#13ce66" inactive-color="#ff4949"></el-switch>
				</el-table-column>

				<el-table-column prop="" label="操作" class-name="table-handle" align="center" width="200">
					<template>
						<el-button type="text">编辑</el-button>
						<el-button type="text" class="button-danger">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<school-dialog :schoolHandleData="schoolHandleData"></school-dialog>
	</div>
</template>

<script>
import schoolDialog from './schoolDialog.vue';
export default {
	name: 'schoolManage',
	components: {
    schoolDialog
	},
	data() {
		return {
			schoolTableData: [
				{},
				{},
			],
			schoolHandleData: {},
		}
	},
	methods: {
		handleSchoolButton(handleName) {
			switch (handleName) {
				case 'insert':
					this.schoolHandleData = {
						handle: 'insert',
					};
					break;

				default:
					break;
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/common_c.scss';
/deep/.course-wrapper-body {
	.course-wrapper-function {
		.function-left {
      .input-select,
			.input-search {
				margin-right: 10px;
			}

      .input-select {
        .el-input__inner {
          width: 200px;
        }
      }
		}
	}
}
</style>
